export default {
  'gift.illustration': 'Image',
  'gift.name': 'Gift name',
  'gift.names': 'Gift',
  'gift.unit': 'Unit',
  'gift.description': 'Description',
  'gift.numberOfDistributed': 'Number of gifts',
  'gift.action': 'Action',
  'gift.update': 'Update gifts',
  'gift.create': 'Add new gifts',
  'gift.content.delete': 'Do you want to delete this gift?',
  'gift.placeholder.description': 'Please enter the description',
  'gift.info': 'View gift information',
  'gift.totalAmountWon': 'Total winning quantity:',
  'gift.totalAmountDistributed': 'The total amount has been distributed:',
  'gift.totalExpired': 'The total expiry:',
  'gift.form.name': 'Gift name:',
  'gift.form.unit': 'Unit:',
  'gift.form.description': 'Description:',
  'gift.form.imgTitle': 'Gift pictures:',
  'gift.form.tableTitle': 'Winning history',
  'gift.form.device': 'Recycling machine',
  'gift.form.status': 'State use',

  'gift.winningTime': 'Winning time',
  'gift.qrCode': 'Qr code',
  'gift.recyclingMachine': 'Recycling machine',
  'gift.location': 'Location',
  'gift.qrCodeUsageStatus': 'State use',
  'gift.qrCodeUsageTime': 'Used time',
  'gift.prizeGiver': 'Gift',

  'gift.device.silverBot': 'Interactive robot',
  'gift.device.deliveryBox': 'Transport robot',
  'gift.device.recyclingMachine': 'Recycling machine',

  'gift.status.used': 'Used',
  'gift.status.unused': 'Unused',
  'gift.status.expired': 'Expired',

  'gift.type.param':
    '{status,select, 1 {Interactive robot} 2 {Transport robot} 3 {Recycling machine} other {Unknown}}',
  'gift.status.param': '{status,select, 1 {Used} 2 {Unused} 3 {Expired} other {Unknown}}',
};
