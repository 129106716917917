import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { IRouter } from '@routers/interface';

export const routerDoc: IRouter = {
  path: '/doc',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'doc.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UserOutlined />,
  },
};

export const routerHomeDoc: IRouter = {
  path: '/',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'doc.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  menu: {
    icon: <UserOutlined />,
  },
};


export default routerDoc;