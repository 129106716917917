export default {
  'robot.title': 'Robot management',
  'robot.code': 'Robot code',
  'robot.name': 'Robot name',
  'robot.robotType': 'Robot type',
  'robot.location': 'Location',
  'robot.lastAccessAt': 'Last access',
  'robot.batteryCapacity': 'Battery',
  'robot.activeStatus': 'Status',
  'robot.createdAt': 'Create day',
  'robot.action': 'Action',
  'robot.madeIn': 'Made in',
  'robot.create': 'Create new robot',
  'robot.description': 'Description',
  'robot.statusOnline': 'Online',
  'robot.statusOffline': 'Offline',
  'robot.modal-createdAt': 'Create day',
  'robot.modal-lastAccessAt': 'Last access',
  'robot.modal-location': 'Location',
  'robot.information': 'Robot information',
  'robot.content.delete': 'Do you want to delete this robot',
  'robot.update': 'Update Robot information',
  'robot.silver-bot': 'Silver bot',
  'robot.delivery-box': 'Delivery box',
  'robot.statusOffline.id': '{status, select, 1 {Online} 2 {Offline} other {Undefined}}',
};
