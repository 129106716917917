export default {
  'customer.name': 'Quản lý khách hàng',
  'customer.customerName': 'Tên khách hàng',
  'customer.customerEmail': 'Email',
  'customer.customerPhone': 'Số điện thoại',
  'customer.customerAddress': 'Địa chỉ',
  'customer.customerCoin': 'Số tiền',
  'customer.customerCard': 'Thẻ',
  'customer.action': 'Hành động',
  'customer.internalComment': 'Ghi chú',
  'customer.update': 'Cập nhật khách hàng',
  'customer.create': 'Thêm khách hàng',
  'customer.password': 'Mật khẩu',
  'customer.customerCode': 'Mã code',
  'customer.customerBirthday': 'Ngày sinh',
  'customer.customerVerification': 'Trạng thái xác nhận',
  'customer.isActive': 'Trạng thái hoạt động',
  'customer.statusConfirm': 'Đã xác nhận',
  'customer.statusUnConfirm': 'Chưa xác nhận',
  'customer.information': 'Thông tin',
  'customer.delete': 'Xóa khách hàng',
  'customer.content.delete': 'Bạn có chắc chắn muốn xóa khách hàng này không?',
  'customer.amountEntry': 'Trạng thái checkin',
  'customer.customerAvatar': 'Avatar',
  'entryLog.logName': 'Tên nhật ký',
  'entryLog.timeLog': 'Thời gian nhật ký',
};
