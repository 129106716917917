export default {
  'transactions.name': 'Quản lý giao dịch',
  'transactions.transactionName': 'Tên giao dịch',
  'transactions.internalComment': 'Ghi chú',
  'transactions.customer': 'Khách hàng',
  'transactions.device': 'Thiết bị',
  'transactions.amountCoin': 'Tổng tiền',
  'transactions.user': 'Nhân viên',
  'transactions.isActive': 'Trạng thái',
  'transactions.action': 'Hàng động',
  'transactions.information': 'Thông tin giao dịch',
  'transactions.products': 'Sản phẩm',
  'transactions.productsName': 'Tên sản phẩm',
  'transactions.quantity': 'Số lượng',
  'transactions.price': 'Giá',
  'transactions.actived': 'Đã kết toán',
  'transactions.notActive': 'Chưa kết toán',
  'transactions.totalCoin': 'Tổng số tiền',
  'transactions.totalCoinActive': 'Số tiền chưa kết toán',
  'transactions.totalCoinInactive': 'Số tiền đã kết toán',
  'transactions.amountTransactionActive': 'Số giao dịch chưa kết toán',
  'transactions.amountTransactionInactive': 'Số giao dịch đã kết toán',
  'transactions.revenue': 'Kết toán',
  'transactions.type': 'Phân loại',
  'transactions.account.fullName': 'Họ và tên',
  'transactions.delete': 'Xóa giao dịch',
  'transactions.content.delete': 'Bạn có chắc chắn muốn xóa giao dịch này không?',
  'transactions.create': 'Tạo giao dịch',
  'users.filter.customer': 'Tìm kiếm khách hàng',
  'users.filter.user': 'Tìm kiếm nhân viên',
  'transactions.time': 'Tìm kiếm ngày',
  'transactions.total': 'Thành tiền',
  'transactions.type.amountCoin': 'Loại giao dịch',
  'transactions.createDateTime': 'Ngày tạo',
  'products.productName': 'Tên sản phẩm',
};
