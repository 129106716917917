
import { routerHome } from '@view/Home/router';
import { routerPageError } from '@view/PageError/router';

import { IRouter } from './interface';
import { routerHomeDoc } from '@view/Doc/router';

export const privatePage: IRouter[] = [
  routerHome,
  routerPageError,
];

export const publicPage: IRouter[] = [
  routerHomeDoc,
  routerPageError,
];
